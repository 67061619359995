import React from 'react'
import Photo from '../Images/JamesNavy.jpg'

function About() {
  return (
    <div>
        <div className='p-5 mx-auto'>
          <div className='flex mx-auto md:w-2/3 py-5'>
            <div className='text-4xl text-blue-700 italic text-center'>Our journey starts with a passion to create</div>
          </div>
          <p className='text-lg'>From a young age, I’ve been driven by a deep curiosity to understand how things work.
            When I discovered software development, I realized there was an intricate world hidden in ones and zeros—a foundation upon which our entire reality is built. 
            Software is now a part of everything. 
            My goal is to allow small businesses to connect to their customers digitally through unique software solutions.</p>
          
        </div>
        <div className='p-5 mx-auto'>
          <div className='text-4xl text-blue-700 ml-2 py-2'>Mission</div>
          <p className='text-xl'>Our mission is to create custom software solutions that support small businesses and local organizations, and to provide the best possible service and support to empower our customers to grow.</p>
        </div>
        <div className='p-5 mx-auto'>
          <div className='text-4xl text-blue-700 ml-2 py-2'>Vision</div>
          <p className='text-xl'>Our vision is to become the go-to software development company for small businesses and local organizations in the Merrimac Valley and Southern New Hampshire.</p>
        </div>
        <div>
          <div className='p-5 mx-auto'>
            <div className='text-4xl text-blue-700 ml-2 py-2'>Values</div>
            <div className='text-xl'>
              <ul className='space-y-4'>
                <li><span className='text-blue-700'>Integrity</span> - I believe that we are best measured by the choices we make when they are without consequence. It is important to me to exhibit honesty and transparency to my clients</li>
                <li><span className='text-blue-700'>Quality</span> - Anything worth doing is worth doing well. Quality is often overlooked in where it seems everything is temporary and replaceable. I strive to ensure my customers get the results they want and don't have to settle for "good enough" </li>
                <li><span className='text-blue-700'>Community</span> - Small business owners are an integral part of every community. I believe there is value in finding local solutions to problems.</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className='p-5 mx-auto'>
            <div className='text-4xl text-blue-700 ml-2 py-2'>About James</div>
            <div className='flex flex-col-reverse md:flex-row'>
              <p className='text-xl'>I moved to Massachusetts after serving in the Navy as an Electronics Technician.
                 I fell in love with the the rich history and culture of New England immediately. I have always had an interest in software development, but I started to challenge myself to learn during the lockdown.
                  I found that the intricate tools that shape our lives weren't as out of reach as I thought. I have started to share my love of software development with others by creating online learning resources, and I have also become a volunteer at the local elementary school to start children learning this valuable skill from an early age.</p>
              <img src={Photo} alt='James Wallace' className=' border-2 border-slate-300 rounded-xl float-left mr-5 object-cover max-h-60 max-w-60' />
            </div>
          </div>
        </div>
    </div>
  )
}

export default About