import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';



  

function ContactForm() {

const form = useRef();

const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_oq3qiqi', 'template_ikhdi0x', form.current, {
        publicKey: 'qsXObpCSyLZS5GqZJ',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

    form.current.reset();
};

return (
    <div className='flex flex-col mx-auto'>
        <form ref={form} onSubmit={sendEmail}>
        <div className='flex flex-row'>
          <label className='w-20'>Name</label>
          <input type="text" name="from_name" className='border rounded-md m-2'/>
        </div>
        <div className='flex flex-row'>
          <label className='w-20'>Email</label>
          <input type="email" name="user_email" className='border rounded-md m-2'/>
        </div>
        <div className='flex flex-row'>
          <label className='w-20'>Message</label>
          <textarea name="message" className='border rounded-md m-2'/>
        </div>
        
        <button className='border p-2 px-5 text-2xl m-2 rounded-lg' type='submit'>Send</button>
        
        </form>
    </div>
    
  );
};

export default ContactForm;