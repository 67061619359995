import React from 'react'
import LOGO_White from '../Images/LOGO_White.png'

function Footer() {
    const footerYear = new Date().getFullYear()

  return (
    <footer className="footer p-2 bg-slate-700 text-white pt-4">
      <div className='flex flex-col-reverse md:flex-row justify-around mx-auto'>
        <div>
          <div className=''>
            <a href='/' className='flex flex-row'>
              <img 
                  src={LOGO_White}
                  className='h-14 w-14 hover:scale-105'
              />
              <div className='flex flex-col -space-y-3 text-2xl lg:text-3xl'>
                  <h2>Wallace Software </h2>
                  <h2>Consulting, LLC.</h2>
              </div>
            </a>

            
            
          </div>
          <p>Copyright &copy; {footerYear} All rights reserved</p>
        </div>
        <div className='flex md:hidden border-b-2 my-4 border-white'></div>
        <div className='flex flex-col'>
          <a href='mailto:james@wallacesoftare.io' className='hover:scale-105 hover:text-blue-300'> james@wallacesoftware.io</a>
          <div className='hidden md:flex border-b-2 border-white py-1' />
          <a href='https://maps.google.com/?q=5 Market St, Amesbury, Massachusetts 01913' className='hover:text-blue-300 hover:scale-105'>
            <div>5 Market Square Ste. 206A <br/>Amesbury, MA 01913</div>
          </a>
        </div>

        
      </div>
    </footer>
  )
}

export default Footer