import React from 'react'
import Logo from '../Components/Logo'

function UnderConstruction() {
  return (
    <div className='h-screen'>
        {/* <div className='flex justify-center mx-auto pt-10'>
            <Logo />
        </div> */}
        <div className='p-5'>
            <h1 className='text-4xl text-blue-700 py-5 text-center mx-auto'>Under Construction</h1>
            <p className='text-2xl text-center mx-auto'>This page is currently under construction.</p>
            <p className='text-2xl text-center mx-auto'>Please check back later for updates.</p>
        </div>
        <div className='flex justify-center mx-auto py-5'>
            <a href='/' className='text-2xl border-2 border-blue-300 p-2 px-5 rounded-full'>Home</a>
        </div>
    </div>
  )
}

export default UnderConstruction