import React, {useState} from 'react'
import ContactForm from '../Components/ContactForm'
import Confirmation from '../Components/Confirmation'

function Contact() {
  
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleFormSubmit = (formData) => {
    // Here you can handle form submission, e.g., send it to an API
    console.log('Form submitted:', formData);
    // After handling submission, update the state to show confirmation
    setIsSubmitted(true);
  };
  
  return (
    <div className='flex flex-col mx-auto w-3/4 py-5'>
      <div>
        <h1 className='text-4xl text-blue-700 py-5 text-center mx-auto'>Contact Us</h1>
        <p className='text-2xl text-center mx-auto'>Do you need a custom software solution to support your company?</p>
        <p className='text-2xl text-center mx-auto'>Want more information on our capabilities?</p>
        <p className='text-2xl text-center mx-auto py-2 text-slate-800'>Reach out to us today!</p>
        <div className='flex mx-auto border-b-2 w-1/2 justify-center p-2 mb-5' />
        {/* <div className='text-center py-5'>
          Social Media
        </div> */}
        {/* <div className='flex mx-auto border-b-2 w-1/2 justify-center p-2' /> */}
      </div>
      
      <div>
        {isSubmitted ? (
          <Confirmation />
        ) : (
          <ContactForm onSubmit={handleFormSubmit} />
        )}
      </div>

      {/* <ContactForm /> */}
        
    </div>
  )
}

export default Contact