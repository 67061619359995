import React from 'react'
import LogoArt from "../Images/Logo.png"

function Logo() {
  return (
    <div className="flex flex-row">
        <a href="/" className="-m-1.5 p-1.5">
            <img src={LogoArt} alt="" className='h-12 lg:h-14 w-auto'/>
        </a>
        <div className='hidden md:flex md:flex-col -space-y-3 text-2xl lg:text-3xl'>
            <h2>Wallace Software </h2>
            <h2>Consulting, LLC.</h2>
        </div>
        
    </div>
  )
}

export default Logo