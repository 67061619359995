import Header from './Components/Header';
import Hero from './Components/Hero';
import Home from './Pages/Home';
import About from './Pages/About';
import Portfolio from './Pages/Portfolio';
import Contact from './Pages/Contact';
import Services from './Components/Services';
import Testimonials from './Components/Testimonials';
import UnderConstruction from './Pages/UnderConstruction';
import NotFound from './Pages/notfound';
import Footer from './Components/Footer';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom'

function App (){
    return (
        // <div className="">
        //     <Header />
        //     <Hero />
        //     <Services />
        //     {/* <Testimonials /> */}
        //     {/* About Us*/}
        //     {/* Our Work */}
        //     <Footer />
        // </div>
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/portfolio" element={<UnderConstruction />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/*" element={<NotFound />} />
            </Routes>
            <Footer />
        </Router>
    )
}

export default App;