import React from 'react'


function notfound() {
  return (
    <div className='flex flex-col items-center mx-auto h-screen'>
        <p className='p-5 text-4xl'>Oh no!</p>
        <p>The page you are looking for cannot be found...</p>
    </div>
  )
}

export default notfound